<template>
  <div class="text">
    <div class="text-content clearfix">
      <div class="tip" v-if="content">
        <!-- 考试倒计时：{{ limitTime }} 总分{{ content.exam_info.score }}分
        {{ content.exam_info.pass }}分及格 -->
      </div>
      <div style="display:flex;">
        <div class="left">
        <!-- <div class="left-title">答题卡</div> -->
        <ul class="answer-sheet">
          <li class="category">
            <p class="category-title">
              考试成绩：<span>{{ content.score }}</span>
            </p>
          </li>
          <li class="time">开始时间：{{ content.created_at }}</li>
          <li class="time">结束时间：{{ content.updated_at }}</li>
        </ul>
      </div>
      <div class="right">
        <ul>
          <li>
            <!-- <div class="title">
              {{ item.type_name }}（共{{ item.number }}题，合计{{
                item.total_score
              }}分）
            </div> -->
            <ul class="category">
              <li v-for="(timu, timuIndex) in selectedDta" :key="timu.id">
                <div class="question-title">
                  {{ timuIndex + 1 }}、{{ timu.question }}
                </div>
                <label
                  class="question-data"
                  v-for="data in timu.selectdata"
                  :key="data.key"
                >
                  <template v-if="timu.type !=2">
                    <input
                      v-if="timu.user_answer == data.key"
                      disabled
                      v-model="data.key"
                      type="radio"
                      :name="'timu_' + timu.id"
                      :value="data.key"
                    />
                    <input
                      v-else
                      disabled
                      type="radio"
                      :name="'timu_' + timu.id"
                      :value="data.key"
                    />
                  </template>
                  <template v-if="timu.type == 2">
                    <template v-for="(keyNum,index) in timu.user_answer">
                      <input
                      :key="index"
                      v-if="keyNum == data.key"
                      disabled
                      v-model="data.key"
                      type="checkbox"
                      :name="'timu_' + timu.id"
                      :value="data.key"
                    />
                    </template>
                    <input
                      disabled
                      type="checkbox"
                      :name="'timu_' + timu.id"
                      :value="data.key"
                    />
                  </template>
                  <span>{{ data.value }}</span>
                </label>
                <p class="sure true" v-if="timu.is_right == 1">
                  答题结果：<span>对</span>
                </p>
                <p class="sure false" v-if="timu.is_right == 0">
                  答题结果：<span>错</span>
                </p>
                <p class="sure" v-if="timu.is_right == 0">
                  正确答案：{{ timu.answer }}
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <!-- <div class="ope">
          <button class="subBtn" @click="subBtn">提交</button>
        </div> -->
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "TextContent",
  data() {
    return {
      content: "",
      selected: "",
      selectedDta: "",
      limitTime: "",
      typeIndex:'',
    };
  },
  mounted() {
      this.typeIndex = this.$route.query.type
    this.getTextContent(); //获取考试内容
    // this.ShowTipModal({
    //   text: "返回", //提示弹窗内容
    //   fun: function () {
    //     // this.$router.go(-1)
    //     this.$router.push("/learnCenter/list?data="+this.$route.query.state);
    //   },
    // });
  },
  methods: {
    ...mapMutations(["ShowTipModal"]), //登录弹窗
    //获取考试内容
    getTextContent() {
      let url =  this.typeIndex==1? '/v1/personal/examRecord' : '/v1/openClass/examRecord'

      this.$axios
        .post(
          url,
          {
            record_id: this.$route.query.id,
          },
          { useLog: true }
        )
        .then((res) => {
          if (res.code == 0) {
            let content = res.data;
            this.content = content;
            this.selectedDta = content.record;
            this.limitTime = content.score + "分";
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
              fun: function () {
                // this.$router.go(-1)
                this.$router.push(
                  "/learnCenter/list?data=" + this.$route.query.state
                );
              },
            });
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.text {
  background-color: #f7f7f7;
  padding-bottom: 20px;
  overflow: hidden;
}
.text-content {
  width: 100%;
  margin: 0 auto;
}
.tip {
  padding: 20px 0;
  font-weight: 900;
}
.left {
  box-sizing: border-box;
  width: 272px;
  padding: 0 20px;
  background-color: #fff;
  float: left;
  // position: -webkit-sticky;
  // position: sticky;
  // top: 0px;
  ul {
    li.time {
      line-height: 40px;
      font-size: 14px;
    }
  }
}
.left-title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 20px;
    background-color: #1a8cfe;
    margin-right: 10px;
  }
}
.category {
  overflow: hidden;
  .sure {
    padding: 10px 0;
  }
  .sure.true {
    color: green;
  }
  .sure.false {
    color: red;
  }
}
.category-title {
  font-size: 14px;
  color: #28275a;
  font-weight: bold;
  margin: 14px 0 15px;
  span {
    font-size: 24px;
    color: red;
  }
}
.test-num-box {
  float: left;
  margin: 0 10px 10px 0;
}
.test-num {
  display: block;
  width: 28px;
  height: 20px;
  background: #f2f2f9;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #b9b6bb;
  &.active {
    background-color: #1a8cfe;
    color: #fff;
  }
}
.right {
  box-sizing: border-box;
  flex: 1;
  margin-left: 20px;
  background-color: #fff;
  border: 1px solid #dfe0e0;
  border-top: none;
  float: right;
  .title {
    height: 60px;
    border-top: 1px solid #dfe0e0;
    border-bottom: 1px solid #dfe0e0;
    background: #fafafa;
    line-height: 60px;
    font-size: 18px;
    font-weight: bold;
    color: #313052;
    padding-left: 20px;
  }
  .category {
    padding: 30px;
    li {
      border-bottom: 1px solid #dfe0e0;
      margin-bottom: 20px;
    }
  }
}
.question-data {
  display: block;
  margin-bottom: 22px;
  display: flex;
  color: #352c50;
  font-size: 14px;
  cursor: pointer;
  input {
    margin: 4px 6px 0 0;
  }
  .active {
    color: #409eff;
  }
}
.question-title {
  font-size: 16px;
  margin-bottom: 26px;
}
.ope {
  background-color: #f7f7f7;
  overflow: hidden;
  .subBtn {
    padding: 10px 20px;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    float: right;
    margin: 20px;
    cursor: pointer;
  }
}
</style>